import React from "react";
import "./About.css";
import ME from "../../assets/me-about.png";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>skills</h5>
              <small>8+ Years Working</small>
            </article>
            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Worked as</h5>
              <small>Software / FullStack Developer</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>80+ Projects</small>
            </article>
          </div>
          <p>
            I'm a skilled Software Developer with experience in both FrontEnd
            and BackEnd development. Proficient in HTML, CSS, Bootstrap, Vanilla
            JavaScript (including jQuery, React and Vue), I specialize in
            creating responsive user interfaces. My backend expertise includes
            PHP (particularly CodeIgniter and Laravel), enabling me to develop
            robust solutions. Additionally, I have a strong background in
            WordPress development and design. I also leverage Vue js for
            interactive UIs and MySQL for efficient database management. With a
            commitment to staying updated on the latest technologies, I bring
            creativity and innovation to every project.
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto:parvathyvd@gmail.com"
            className="btn btn-primary"
          >
            Let's talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
